<template>
	<div
		style="
			position: sticky;
			bottom: 20px;
			width: fit-content;
			margin: 10px auto;
		"
	>
		<q-btn
			v-if="$q.screen.gt.sm"
			rounded
			icon="save"
			padding="md lg"
			label='Save'
			color="primary"
			@click="$emit('save')"
		/>
		<q-btn
			v-else
			round
			icon="save"
			size="lg"
			color="primary"
			@click="$emit('save')"
		>
			<q-tooltip anchor="top middle" :delay="500">
				Save
			</q-tooltip>
		</q-btn>
	</div>
</template>

<script>
export default {
	name: 'SaveButton',
}
</script>
