<template>
	<q-item tag="label" dense class="q-my-sm">
		<q-item-section>
			<q-item-label>{{ switch_setting.label }}</q-item-label>
		</q-item-section>
		<q-item-section avatar>
			<q-toggle v-model="value" />
		</q-item-section>
	</q-item>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'ConferenceSwitchFields',
	props: {
		switch_setting: Object,
	},
	computed: {
		value: {
			get() {
				return this.$store.getters.conference_settings[
					this.switch_setting.value
				]
			},
			set(val) {
				let conference_settings = JSON.parse(
					JSON.stringify(this.$store.getters.conference_settings)
				)
				conference_settings[this.switch_setting.value] = val
				this.updateConferenceSettings(conference_settings)
			},
		},
	},
	methods: {
		...mapActions(['updateConferenceSettings']),
	},
}
</script>
