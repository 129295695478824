<template>
	<div class="flex column justify-between items-start q-my-sm">
		<div class="full-width">
			<q-input
				outlined
				v-model="value"
				:label="input_setting.label"
				:name="input_setting.name"
				:hint="input_setting.helper_text"
			/>
		</div>
		<preview-button
			:is_disabled="value"
			:loading="loading"
			@playTTS=playTTS
		/>
	</div>
</template>

<script>
import MessageFormAPI from '@/services/api/MessageForm.js'
import { mapActions, mapGetters } from 'vuex'
import PreviewButton from '@/components/datasets/utils/PreviewButton.vue'

export default {
	name: 'ConferenceInputFields',
	components: { PreviewButton },
	props: {
		input_setting: Object,
	},
	data () {
		return {
			loading: false
		}
	},
	computed: {
		...mapGetters(['default_tts_voice']),
		value: {
			get() {
				return this.$store.getters.conference_settings[this.input_setting.value]
			},
			set(val) {
				let conference_settings = JSON.parse(
					JSON.stringify(this.$store.getters.conference_settings)
				)
				conference_settings[this.input_setting.value] = val
				this.updateConferenceSettings(conference_settings)
			},
		},
	},
	methods: {
		...mapActions(['updateConferenceSettings']),
		async playTTS() {
			this.loading = true
			let name = this.default_tts_voice.name ? this.default_tts_voice.name : 'Amy'
			let message = {
				message: this.value,
				tts_voice: name,
			}
			const response = await MessageFormAPI.getTTSAudio(message)
			this.loading=false

			let audio = new Audio(response)
			audio.play()
		},
	},
}
</script>

