<template>
	<div class="flex column" v-if="customer_group_permissions.conference_bridge">
		<div>
			<h1 class="setting-title">
				Conference settings
			</h1>
			<div
				class="settings-wrapper"
				v-for="switch_setting in switch_settings"
				:key="switch_setting.name"
			>
				<conference-switch-fields :switch_setting="switch_setting" />
			</div>
			<div
				class="settings-wrapper"
				v-for="input_setting in input_settings"
				:key="input_setting.name"
			>
				<conference-input-fields :input_setting="input_setting" />
			</div>

		</div>
		<save-button @save="save" />
	</div>
</template>

<script>
import ConferenceInputFields from '@/components/datasets/edit/conference/ConferenceInputFields.vue'
import ConferenceSwitchFields from '@/components/datasets/edit/conference/ConferenceSwitchFields.vue'
import { mapActions, mapGetters } from 'vuex'
import SaveButton from '@/components/datasets/utils/SaveButton.vue'

export default {
	name: 'Conference',
	components: {
		ConferenceInputFields,
		ConferenceSwitchFields,
		SaveButton,
	},
	computed: {
		...mapGetters(['customer_group_permissions']),
		input_settings() {
			return [
				{
					label: 'Conference intro',
					name: 'default-conference-info',
					helper_text: 'Default conference intro',
					value: 'default_message_live',
				},
				{
					label: 'Conference AMD',
					name: 'default-conference-amd',
					helper_text: 'Default conference AMD',
					value: 'default_message_amd',
				},
			]
		},
		switch_settings() {
			return [
				{
					label: 'Allow operators to create/edit conference calls',
					name: 'allow-operators-create-or-edit',
					value: 'allow_operators_create_or_edit',
				},
				{
					label: 'Allow operators to run conference',
					name: 'allow-operators-run',
					value: 'allow_operators_run',
				},
			]
		},
	},
	methods: {
		...mapActions(['saveConferenceSettings', 'fetchUser']),
		async save() {
			const response = await this.saveConferenceSettings(this.$route.params.id)
			if (response.error) {
				this.$q.notify({
					timeout: 6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				return
			}
			this.$q.notify(response)
			await this.fetchUser()
		},
	},
}
</script>
