<template>
	<div class="preview-wrapper">
		<q-btn
			outline
			rounded
			color="primary"
			size="md"
			:disable="!is_disabled"
			@click="$emit('playTTS')"
		>
			<div v-if="!loading">
				<q-icon name="volume_up" class="q-mr-sm"/>
				<span>Preview</span>
			</div>
			<q-spinner-bars
				v-else
				color="primary"
			/>
		</q-btn>


	</div>
</template>

<script>
export default {
	name: 'PreviewButton',
	props: {
		is_disabled: String,
		loading: Boolean
	}
}
</script>

<style lang="scss" scoped>
.preview-wrapper {
	margin-top: 0.5rem;
}

@media (max-width: $breakpoint-sm-max) {
	.preview-wrapper {
		margin-top: 1.5rem;
	}
}
</style>

